// see the styleguide for common uses of these colors
// https://zeroheight.com/6650518aa/p/55f52b

// primary colors
$white-100: #fff;
$black-100: #000;
$ice-100: #f7f9fb;
$cream-90: #fffcf9;
$cream-100: #fef9f5;
$cream-110: #faf0e8;

$green-50: #e5f2e5;
$green-70: #99ce99;
$green-100: #008600;
$green-110: #007200;
$green-120: #005d00;
$green-130: #004400;
$green-140: #415942;

$lavender-120: #824ba9;
$lavender-100: #b57edc;

$charcoal-50: #f5f5f6;
$charcoal-60: #d8dadc;
$charcoal-70: #b2b5b9;
$charcoal-80: #8c9196;
$charcoal-90: #666c73;
$charcoal-100: #404851;

$red-50: #fdf3f4;
$red-100: #d92236;
$red-110: #b81c2d;
$red-120: #971725;

// accent and label colors
$yellow-50: #fef7e9;
$yellow-100: #f5b623;

$blue-50: #f4f8fd;
$blue-100: #2a7de1;
$blue-120: #1d579d;
$blue-130: #0c355b;
$orange-100: #f07b00;
$orange-120: #a75500;

$lime-100: #b7bf10;
$plum-100: #6f1329;
$blueberry-100: #4c5c74;

$holiday: #ab2328;

$vegetarian-100: #ccd5ae;
$pescatarian-100: #c8d5e4;
$keto-100: #fbf194;
$gluten-100: #cce3de;
$med-100: #d3e7f9;
$paleo-100: #e4ddec;

// Undergoing design review
$purple-100: #623177;
$teal-100: #05857c;
$smoky-blue: #00809c;

// third party colors
$blue-facebook: #4267b2;
$blue-kroger: #0e6ab1;
$blue-twitter: #1da1f2;
$red-pinterest: #cb2027;
$blue-google: #346ef1;

// Tempo colors
$tempo-blue-10: #f6fbff;
$tempo-blue-50: #dff2ff;
$tempo-blue-70: #7fc1fb;
$tempo-blue-80: #5aa7ec;
$tempo-blue-90: #1e629e;
$tempo-blue-100: #1071ea;
$tempo-blue-110: #0558c3;
$tempo-blue-120: #00439c;

$tempo-green-90: #7ecc89;
$tempo-green-100: #34a86e;
$tempo-green-120: #006028;

$tempo-navy-90: #003485;
$tempo-navy-100: #00255f;
$tempo-yellow-100: #eec23d;
$tempo-orange-100: #ff7a00;
$tempo-pink-100: #ffd6c9;
$tempo-purple-100: #9d5bf7;
$tempo-black-100: #2b2b2b;
$tempo-lime-100: #c0e235;

$tempo-gray-40: #fbfafa;
$tempo-gray-100: #f4f3f2;

// native CSS variables
html {
  --white-100: #{$white-100};
  --black-100: #{$black-100};
  --ice-100: #{$ice-100};

  --green-50: #{$green-50};
  --green-70: #{$green-70};
  --green-100: #{$green-100};
  --green-110: #{$green-110};
  --green-120: #{$green-120};

  --gray-40: #{$tempo-gray-40};

  --charcoal-50: #{$charcoal-50};
  --charcoal-60: #{$charcoal-60};
  --charcoal-70: #{$charcoal-70};
  --charcoal-80: #{$charcoal-80};
  --charcoal-90: #{$charcoal-90};
  --charcoal-100: #{$charcoal-100};

  --red-50: #{$red-50};
  --red-100: #{$red-100};
  --red-110: #{$red-110};
  --red-120: #{$red-120};

  // accent and label colors
  --yellow-50: #{$yellow-50};
  --yellow-100: #{$yellow-100};

  --blue-50: #{$blue-50};
  --blue-100: #{$blue-100};
  --blue-120: #{$blue-120};

  --orange-100: #{$orange-100};
  --orange-120: #{$orange-120};

  --lime-100: #{$lime-100};
  --plum-100: #{$plum-100};
  --blueberry-100: #{$blueberry-100};
  --blue-130: #{$blue-130};
  --purple-100: #{$purple-100};

  // third party colors
  --blue-facebook: #{$blue-facebook};
  --blue-kroger: #{$blue-kroger};
  --blue-twitter: #{$blue-twitter};
  --red-pinterest: #{$red-pinterest};

  // tailwind theme customization
  --tw-ring-color: #{$green-70};
}
